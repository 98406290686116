import axios from 'axios';
import ISkuList from '@/types/ISkuList';

export default class RepricingRepository {
  static getMarketplaces() {
    return axios.get('repricing/marketplaces')
  }

  static getRepricingList(marketplaceId: string) {
    return axios.get(`repricing/marketplaces/${marketplaceId}/repricing`)
  }

  static updateMinPrice(marketplaceId: string, sku: string, price: number|string) {
    return axios.put(`repricing/marketplaces/${marketplaceId}/${sku}/min-price`, {price})
  }

  static updateMaxPrice(marketplaceId: string, sku: string, price: number|string) {
    return axios.put(`repricing/marketplaces/${marketplaceId}/${sku}/max-price`, {price})
  }

  static setPrice(marketplaceId: string, sku: string, price: number|string) {
    return axios.put(`repricing/marketplaces/${marketplaceId}/${sku}/set-price`, {price})
  }

  static getMarketplaceEvents(marketplaceId: string) {
    return axios.get(`repricing/marketplaces/${marketplaceId}/events`)
  }

  static updateMerchantListing(marketplaceId: string) {
    return axios.post(`repricing/marketplaces/${marketplaceId}/merchant-listing`)
  }

  static updateSuggestedPrice(marketplaceId: string) {
    return axios.post(`repricing/marketplaces/${marketplaceId}/suggested-price`)
  }

  static updateAmazonPrices(marketplaceId: string, skuList: ISkuList) {
    return axios.post(`repricing/marketplaces/${marketplaceId}/price`, skuList)
  }

  static importExcel(marketplaceId: string, formData: FormData) {
    return axios.post(`repricing/marketplaces/${marketplaceId}/import`, formData)
  }

  static downloadExcel(marketplaceId: string) {
    return axios.get(`repricing/marketplaces/${marketplaceId}/export`, { responseType: 'blob' })
  }
}
