







import {Component, Prop, Vue} from 'vue-property-decorator';
import {formatNumberWithSpaces} from '@/utils/universalUtils';
import {dateToYYYYmmDDHHmm} from '@/utils/dateTime';

@Component({name: 'UneditablePriceCell'})
export default class UneditablePriceCell extends Vue {
  @Prop()
  currency!: string

  @Prop()
  price!: string | number

  @Prop()
  date!: string

  formatPrice(price: string | number) {
    return formatNumberWithSpaces(price, 2)
  }
  formatDate(dateTime: string): string {
    return dateTime ? dateToYYYYmmDDHHmm(dateTime) : '';
  }

}
