












































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {DataTableHeader} from 'vuetify';
import {IRepricingItem} from '@/types/repricing';
import {nullablePrice} from '@/utils/validationRules';
import {resolveError} from '@/utils/notifications';
import RepricingService from '@/services/RepricingService';
import UneditablePriceCell from '@/components/Repricing/UneditablePriceCell.vue';
import CopyButton from "@/components/CopyButton.vue";

@Component({
  name: 'RepricingTable',
  components: {CopyButton, UneditablePriceCell}
})
export default class RepricingTable extends Vue {
  @Prop()
  data!: IRepricingItem[]

  @Prop()
  loading!: boolean

  @Prop()
  restoreAllPrices!: boolean

  @Prop()
  uncheckTableRows!: boolean

  @Prop()
  marketplaceId!: string

  rules = {
    minPrice: [nullablePrice],
    maxPrice: [nullablePrice],
    setPrice: [nullablePrice]
  }

  savingCells: {sku: string, columnName: string}[] = []

  selectedItems: IRepricingItem[] = []

  editedPrices: {
    sku: string,
    columnName: string,
    price: number|string
  }[] = []

  headers: DataTableHeader[] = [
    {
      text: 'SYSTEM_SKU',
      value: 'name',
    },
    {
      text: 'SYSTEM_ACTUAL_PRICE',
      value: 'price',
    },
    {
      text: 'SYSTEM_MIN_PRICE',
      value: 'minPrice',
    },
    {
      text: 'SYSTEM_MAX_PRICE',
      value: 'maxPrice',
    },
    {
      text: 'SYSTEM_SUGGESTED_PRICE',
      value: 'suggestedPrice',
    },
    {
      text: 'SYSTEM_SET_PRICE',
      value: 'setPrice',
    },
    {
      text: 'SYSTEM_INFO',
      value: 'options',
    }
  ]

  editableColumns: string[] = [
    'minPrice', 'maxPrice', 'setPrice'
  ]

  @Watch('restoreAllPrices')
  onRestoreAllPrices(value: boolean) {
    if (value) {
      this.editedPrices = []
      this.$emit('priceRestored')
    }
  }

  @Watch('uncheckTableRows')
  onUncheckedTableRows(value: boolean) {
    if (value) {
      this.selectedItems = []
      this.$emit('uncheckedTableRows')
    }
  }

  @Watch('selectedItems')
  onSelectedItems(value: IRepricingItem[]) {
    this.$emit('itemSelected', value);
  }

  async validateRowPrices(sku: string, currentColumn: string) {
    for (let i = 0; i < this.editableColumns.length; i++) {
      const col = this.editableColumns[i]
      // @ts-ignore
      if (this.$refs[`${col}${sku}`][0].validate() && col !== currentColumn && this.getEditedPrice(col, sku)) {
        await this.save(col, sku)
      }
    }
  }

  priceComparator(columnName: string, sku: string, value: number | string) {
    if (!value) {
      return true
    }

    switch (columnName) {
      case 'minPrice': {
        const price = this.getPrice('setPrice', sku)
        if (price && Number(value) > Number(price)) {
          return this.$t('SYSTEM_SHOULD_BE_LTE_SET_PRICE')
        }
        const maxPrice = this.getPrice('maxPrice', sku)
        return !maxPrice ||  Number(maxPrice) >=  Number(value) || this.$t('SYSTEM_MIN_PRICE')
      }
      case 'maxPrice': {
        const price = this.getPrice('setPrice', sku)
        if (price && Number(value) < Number(price)) {
          return this.$t('SYSTEM_SHOULD_BE_GTE_SET_PRICE')
        }
        const minPrice = this.getPrice('minPrice', sku)
        return !minPrice || Number(minPrice) <= Number(value) || this.$t('SYSTEM_MAX_PRICE')
      }
      case 'setPrice': {
        const minPrice = this.getPrice('minPrice', sku)
        const maxPrice = this.getPrice('maxPrice', sku)

        if (minPrice && Number(value) < Number(minPrice)) {
          return this.$t('SYSTEM_SHOULD_BE_GTE_THAN_MIN_PRICE')
        } else if (maxPrice && Number(value) > Number(maxPrice)) {
          return this.$t('SYSTEM_SHOULD_BE_LTE_THAN_MAX_PRICE')
        }
        return true
      }
      default:
        return true
    }
  }

  getPrice(columnName: string, sku: string): string|number|null {
    const editedPrice = this.getEditedPrice(columnName, sku)

    return editedPrice || this.getOriginalPrice(columnName, sku)
  }

  getEditedPrice(columnName: string, sku: string): string|number|null {
    const candidate = this.editedPrices
      .find(item => item.sku === sku && item.columnName === columnName)

    return candidate?.price !== undefined ? candidate.price : null
  }

  getOriginalPrice(columnName: string, sku: string): string|number|null {
    const candidate = this.data.find(item => item.sku === sku)

    // @ts-ignore
    return candidate[columnName] || null
  }

  setNewPrice(columnName: string, sku: string, price: number|string) {
    const editedPrice = this.editedPrices
      .find(item => item.sku === sku && item.columnName === columnName)

    if (editedPrice) {
      editedPrice.price = price
    } else {
      this.editedPrices.push({sku, columnName, price})
    }

    this.validateRowPrices(sku, columnName)
  }

  restorePrice(sku: string, columnName: string) {
    this.editedPrices = this.editedPrices
      .filter(item => !(item.sku === sku && item.columnName === columnName))
  }

  async save(columnName: string, sku: string, refreshList = true) {
    // @ts-ignore
    if (this.$refs[`${columnName}${sku}`][0].validate()) {
      const price = this.getEditedPrice(columnName, sku)

      if (price !== null && this.priceComparator(columnName, sku, price) === true) {
        try {
          this.setSavingCell(sku, columnName)
          await RepricingService.updatePrice(this.marketplaceId, sku, columnName, price || '')
          this.restorePrice(sku, columnName)
        } catch (e) {
          resolveError(e, 'save')
        } finally {
          if (refreshList) {
            this.$emit('priceSaved')
          }
          this.removeSavingCell(sku, columnName)
        }
      }
    }
  }

  removeSavingCell(sku: string, columnName: string) {
    this.savingCells = this.savingCells.filter(item => !(item.sku === sku && item.columnName === columnName))
  }

  setSavingCell(sku: string, columnName: string) {
    const isLoading = this.savingCells.some(item => item.sku === sku && item.columnName === columnName)
    if (!isLoading) {
      this.savingCells.push({sku, columnName})
    }
  }

  isSaving(sku: string, columnName: string) {
    return this.savingCells.some(item => item.sku === sku && item.columnName === columnName)
  }

  saveSelected() {
    //
  }
}
