import {IRepricingItem} from '@/types/repricing';
import {formatNumberWithSpaces} from '@/utils/universalUtils';

export default class RepricingFactory {
  static toRepricingItem(data: any): IRepricingItem {
    const checkPrices = () => {
      if (data.maxPrice && data.setPrice && data.minPrice && !(data.maxPrice >= data.setPrice && data.setPrice >= data.minPrice)) {
        return 'SYSTEM_CHECK_PRICES'
      }
      return null
    }

    const warnings = []

    if (data.warning) {
      warnings.push(data.warning)
    }

    const priceWarning = checkPrices()
    if (priceWarning) {
      warnings.push(priceWarning)
    }

    return {
      currency: data.currency || '',
      maxPrice: data.maxPrice ? formatNumberWithSpaces(data.maxPrice, 2) : '',
      minPrice: data.minPrice ? formatNumberWithSpaces(data.minPrice, 2) : '',
      price: data.price || '',
      setPrice: data.setPrice ? formatNumberWithSpaces(data.setPrice, 2) : '',
      sku: data.sku || '',
      asin: data.asin || '',
      name: data.name || '',
      url: data.url || '',
      suggestedPrice: data.suggestedPrice || '',
      setPriceAt: data.setPriceAt || '',
      suggestedPriceAt: data.suggestedPriceAt || '',
      locked: data.locked || false,
      updatedPriceAt: data.updatedPriceAt || '',
      warnings,
      error: data.error || ''
    }
  }

  static toMarketplace(data: any): {id: string, name: string} {
    return {
      id: data.id,
      name: data.name
    }
  }
}
