
















































































































import {Component, Vue} from 'vue-property-decorator';
import {NotificationTypes, notify, resolveError} from '@/utils/notifications';
import RepricingTable from '@/components/Repricing/RepricingTable.vue';
import {IRepricingItem} from '@/types/repricing';
import {marketpalces} from '@/constants/Marketplace';
import RepricingService from '@/services/RepricingService';
import LastUpdateInfo from '@/components/Repricing/LastUpdateInfo.vue';
import FileUploadButton from '@/components/FileUploadButton.vue';
import {getSavedPageData, savePageData} from '@/utils/pageDataSaver';
import PageWrap from '@/components/PageWrap.vue';

@Component({name: 'Repricing',
  components: {PageWrap, FileUploadButton, LastUpdateInfo, RepricingTable}
})
export default class Repricing extends Vue {
  marketplaceId: string|null = null
  priceListLoading: boolean = false

  marketplaces: {id: string, name: string}[] = []
  repricingItems: IRepricingItem[] = []

  selectedItems: IRepricingItem[] = []

  lastUpdateMerchantListingAt: string = ''
  currentlyUpdatingMerchantListing: boolean = false
  lastUpdateSuggestionAt: string = ''
  currentlyUpdatingSuggestion: boolean = false

  restoreAllPrices: boolean = false
  uncheckTableRows: boolean = false

  updateAmazonPricesLoading: boolean = false
  updateMerchantListingLoading: boolean = false
  updateSuggestedPriceLoading: boolean = false
  exportExcelLoading: boolean = false
  importLoading: boolean = false

  async mounted() {
    this.getSavedFilters()
    await this.fetchMarketplaces()
    if (this.marketplaces.length) {
      if (!this.marketplaceId) {
        this.marketplaceId = this.marketplaces[0].id
      }
      await this.getMarketplaceEvents()
      await this.generatePriceList()
    }
  }

  getSavedFilters() {
    const { filters } = getSavedPageData()
    if (filters) {
      this.marketplaceId = filters.marketplaceId || null
    }
  }

  updateData() {
    this.generatePriceList()
    this.getMarketplaceEvents()
  }

  async importExcelFile(file: File) {
    try {
      this.importLoading = true
      if (this.marketplaceId) {
        await RepricingService.importExcel(this.marketplaceId, file)
      }
      this.importLoading = false
      notify(NotificationTypes.success, this.$t('SYSTEM_FILE_WAS_IMPORTED'))
      await this.generatePriceList()
    } catch (e) {
      resolveError(e, 'import')
    } finally {
      this.importLoading = false
    }
  }

  async exportExcel() {
    try {
      this.exportExcelLoading = true
      try {
        if (this.marketplaceId) {
          await RepricingService.exportExcel(this.marketplaceId)
        }
      } catch (e) {
        resolveError(e, 'export')
      } finally {
        this.exportExcelLoading = false
      }
    } catch (e) {
      resolveError(e, 'export_selected_items')
    } finally {
      this.exportExcelLoading = false
    }
  }

  async updateAmazonPrices() {
    try {
      this.updateAmazonPricesLoading = true
      if (this.marketplaceId) {
        await RepricingService.updateAmazonPrices(this.marketplaceId, this.selectedItems)
        await this.generatePriceList()

      }
    } catch (e) {
      resolveError(e, 'update_amazon_prices')
    } finally {
      this.updateAmazonPricesLoading = false
    }
  }

  async updateMerchantListing() {
    try {
      if (this.marketplaceId) {
        this.updateMerchantListingLoading = true
        await RepricingService.updateMerchantListing(this.marketplaceId)
        await this.getMarketplaceEvents()
      }
    } catch (e) {
      resolveError(e, 'fetch_update_merchant_listing')
    } finally {
      this.updateMerchantListingLoading = false
    }
  }

  async updateSuggestedPrice() {
    try {
      if (this.marketplaceId) {
        this.updateSuggestedPriceLoading = true
        await RepricingService.updateSuggestedPrice(this.marketplaceId)
        await this.getMarketplaceEvents()
      }
    } catch (e) {
      resolveError(e, 'fetch_suggested_price')
    } finally {
      this.updateSuggestedPriceLoading = false
    }
  }

  async getMarketplaceEvents() {
    try {
      if (this.marketplaceId) {
        const {
          lastUpdateMerchantListingAt,
          lastUpdateSuggestionAt,
          currentlyUpdatingMerchantListing,
          currentlyUpdatingSuggestion
        } = await RepricingService.getMarketplaceEvents(this.marketplaceId)
        this.lastUpdateMerchantListingAt = lastUpdateMerchantListingAt || ''
        this.currentlyUpdatingMerchantListing = currentlyUpdatingMerchantListing
        this.currentlyUpdatingSuggestion = currentlyUpdatingSuggestion
        this.lastUpdateSuggestionAt = lastUpdateSuggestionAt || ''
      }
    } catch (e) {
      resolveError(e, 'fetch_marketplace_events')
    }
  }

  async fetchMarketplaces() {
    try {
      this.marketplaces = await RepricingService.getMarketplaces()
    } catch (e) {
      resolveError(e, 'fetch_marketplaces')
    }
  }

  currencyAccordingToMarketplace(): string {
    const selectedMarketplace = this.marketplaces.find(item => item.id === this.marketplaceId)
    if (selectedMarketplace) {
      const domain = selectedMarketplace.name.split('Amazon.')[1]
      const marketplace = marketpalces.find(marketplace => marketplace.domain === domain)
      if (marketplace) {
        return marketplace.currency
      }
    }
    return ''
  }

  onItemSelected(selectedItems: IRepricingItem[]) {
    this.selectedItems = selectedItems;
  }

  async generatePriceList() {
    this.priceListLoading = true
    try {
      if (this.marketplaceId) {
        this.repricingItems = await RepricingService.getList(this.marketplaceId)
        this.uncheckTableRows = true
      }
    } catch (e) {
      resolveError(e, 'fetch_data');
    } finally {
      this.priceListLoading = false
    }
  }

  onMarketplaceChanged(value: string|number) {
    this.restoreAllPrices = true
    this.getMarketplaceEvents()
    this.generatePriceList()
    savePageData({
      filters: {marketplaceId: value}
    })
  }
}
