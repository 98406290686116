import RepricingRepository from '@/repositories/RepricingRepository';
import RepricingFactory from '@/factories/RepricingFactory';
import {dateToYYYYmmDDHHmm} from '@/utils/dateTime';
import {IRepricingItem} from '@/types/repricing';
import FileService from '@/services/FileService';

export default class RepricingService {
    static async getMarketplaces() {
        const {data} = await RepricingRepository.getMarketplaces()

        return data.map((item: any) => RepricingFactory.toMarketplace(item))
    }

    static async getList(marketplaceId: string) {
        const {data} = await RepricingRepository.getRepricingList(marketplaceId)

        return data.map((item: any) => RepricingFactory.toRepricingItem(item))
    }

    static async updatePrice(marketplaceId: string, sku: string, columnName: string, price: string | number) {
        switch (columnName) {
            case 'minPrice':
                return RepricingRepository.updateMinPrice(marketplaceId, sku, price)
            case 'maxPrice':
                return RepricingRepository.updateMaxPrice(marketplaceId, sku, price)
            case 'setPrice':
                return RepricingRepository.setPrice(marketplaceId, sku, price)
            default:
                throw 'SYSTEM_CELL_IS_NOT_EDITABLE'
        }
    }

    static async getMarketplaceEvents(marketplaceId: string): Promise<{ lastUpdateMerchantListingAt: string, lastUpdateSuggestionAt: string, currentlyUpdatingMerchantListing: boolean, currentlyUpdatingSuggestion: boolean }> {
        const {
            data: {
                lastUpdateMerchantListingAt, lastUpdateSuggestionAt, currentlyUpdatingMerchantListing, currentlyUpdatingSuggestion
            }
        } = await RepricingRepository.getMarketplaceEvents(marketplaceId)

        return {
            lastUpdateMerchantListingAt: lastUpdateMerchantListingAt ? dateToYYYYmmDDHHmm(lastUpdateMerchantListingAt) : '',
            lastUpdateSuggestionAt: lastUpdateSuggestionAt ? dateToYYYYmmDDHHmm(lastUpdateSuggestionAt.split('T')) : '',
            currentlyUpdatingMerchantListing: currentlyUpdatingMerchantListing,
            currentlyUpdatingSuggestion: currentlyUpdatingSuggestion
        }
    }

    static async updateMerchantListing(marketplaceId: string) {
        return RepricingRepository.updateMerchantListing(marketplaceId)
    }

    static async updateSuggestedPrice(marketplaceId: string) {
        return RepricingRepository.updateSuggestedPrice(marketplaceId)
    }

    static async updateAmazonPrices(marketplaceId: string, selectedItems: IRepricingItem[]) {
        let skuList = selectedItems.map(x => x.sku)
        return RepricingRepository.updateAmazonPrices(marketplaceId, {skuList: skuList})
    }

    static async importExcel(marketplaceId: string, file: File) {
        const bodyFormData = new FormData()
        bodyFormData.append('file', file)

        return RepricingRepository.importExcel(marketplaceId, bodyFormData)
    }

    static async exportExcel(marketplaceId: string) {
        const {data} = await RepricingRepository.downloadExcel(marketplaceId)

        const file = new FileService(data);

        file.downloadFile(`Repricing report ${dateToYYYYmmDDHHmm(new Date())}`)
    }
}
