<template>
  <div class="d-flex flex-wrap">
    <div class="d-flex">
      <v-tooltip
        v-if="tooltipText"
        bottom
      >
        <template #activator="{ attrs, on }">
          <v-icon
            v-on="on"
            v-bind="attrs"
            class="d-flex align-self-start mr-1"
          >
            mdi-information-outline
          </v-icon>
        </template>
        {{ tooltipText }}
      </v-tooltip>

      <div class="text-end mr-2">
        <div>{{ $t(dateInfoText) }}:</div>
        <b>{{ date ? date : $t('SYSTEM_NO_DATA') }}</b>
      </div>
    </div>
    <v-btn
      color="primary"
      :disabled="inProgress"
      :loading="loading"
      @click="$emit('buttonClick')"
    >
      {{ !inProgress ? $t(buttonText) : $t('SYSTEM_IN_PROGRESS') }}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'LastUpdateInfo',
  props: {
    dateInfoText: {
      type: String,
      required: true
    },
    date: {
      type: String,
      required: true
    },
    inProgress: {
      type: Boolean,
      required: true
    },
    buttonText: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    tooltipText: {
      type: String
    }
  }
}
</script>

<style scoped>

</style>
