var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers.map(function (item) { return (Object.assign({}, item, {text: _vm.$t(item.text)})); }),"items":_vm.data,"show-select":"","loading":_vm.loading,"item-key":"sku"},scopedSlots:_vm._u([{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('UneditablePriceCell',{attrs:{"currency":item.currency,"date":item.updatedPriceAt,"price":item.price}})]}},{key:"item.suggestedPrice",fn:function(ref){
var item = ref.item;
return [_c('UneditablePriceCell',{attrs:{"currency":item.currency,"date":item.suggestedPriceAt,"price":item.suggestedPrice}})]}},_vm._l((_vm.editableColumns),function(editableColumn){return {key:("item." + editableColumn),fn:function(ref){
var item = ref.item;
return [_c('div',{key:editableColumn,ref:editableColumn + item.sku,refInFor:true,staticClass:"d-flex mt-2"},[_c('v-form',{ref:editableColumn + item.sku,refInFor:true,on:{"submit":function($event){$event.preventDefault();$event.stopPropagation();}}},[_c('v-text-field',{staticStyle:{"max-width":"200px"},attrs:{"value":_vm.getEditedPrice(editableColumn, item.sku) !== null
            ? _vm.getEditedPrice(editableColumn, item.sku)
            : item[editableColumn],"prefix":item.currency,"rules":_vm.rules[editableColumn].concat( [function (value) { return _vm.priceComparator(editableColumn, item.sku, value); }]),"loading":_vm.isSaving(item.sku, editableColumn),"disabled":_vm.isSaving(item.sku, editableColumn) || item.locked,"dense":"","type":"number"},on:{"input":function($event){
            var i = arguments.length, argsArray = Array(i);
            while ( i-- ) argsArray[i] = arguments[i];
return _vm.setNewPrice.apply(void 0, [ editableColumn, item.sku ].concat( argsArray ))},"blur":function($event){return _vm.save(editableColumn, item.sku)}}})],1)],1)]}}}),{key:"item.options",fn:function(ref){
            var item = ref.item;
return [(item.locked)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v(" mdi-swap-horizontal ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('SYSTEM_AMAZON_PRICE_IS_UPDATING')))])]):_vm._e(),(item.error)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"error"}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-circle ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.error))])]):_vm._e(),(item.warnings.length)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"warning"}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert ")])]}}],null,true)},_vm._l((item.warnings),function(warning,idx){return _c('span',{key:warning + idx},[_vm._v(" "+_vm._s(_vm.$t(warning))+" "),_c('br')])}),0):_vm._e()]}}],null,true),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})}
var staticRenderFns = []

export { render, staticRenderFns }