export const marketpalces = [
  {
    shortcut: 'DE',
    currency: 'EUR',
    domain: 'de'
  },
  {
    shortcut: 'UK',
    currency: 'GBP',
    domain: 'co.uk'
  },
  {
    shortcut: 'FR',
    currency: 'EUR',
    domain: 'fr'
  },
  {
    shortcut: 'PL',
    currency: 'PLN',
    domain: 'pl'
  }
]
